import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { DotPulse } from "@uiball/loaders";
import React, { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import Dropdown from "../../components/Dropdown";
import TableLayoutTwo from "../../components/TableLayout/Two";
import EditorPanelIcon from "@atlaskit/icon/glyph/editor/panel";
import { SelectedProfileCountryAtom } from "../../atom";
import { useRecoilValue } from "recoil";

const Table = ({ data }) => {
  const [quaters, setQuaters] = useState(null);
  const [market, setMarket] = useState("US");
  const [quater, setQuater] = useState(null);
  const [quaterValue, setQuaterValue] = useState("Quater");
  const [initial, setInitial] = useState(true);
  const [tableData, setTableData] = useState(null);
  const [showTooltip, setShowtooltip] = useState(false);
  const selectedProfileCountry = useRecoilValue(SelectedProfileCountryAtom);

  useEffect(() => {
    setMarket(selectedProfileCountry);
  }, [selectedProfileCountry]);

  const colDef = [
    {
      header: "Rank",
      accessorKey: "rank",
    },
    {
      header: "OTT Name",
      accessorKey: "ott_name",
    },
    {
      header: "Viewers (M)",
      accessorKey: "subscribers",
      // cell: ({ getValue }) => (
      //   <p>
      //     {getValue()} M
      //   </p>
      // )
    },
  ];

  const table = useReactTable({
    data: tableData,
    columns: colDef,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    console.log(data, "quats data");
    if (data) {
      const quats = Object.keys(data)
        .map((each) => each.split(" ").reverse().join(" "))
        .sort()
        .reverse()
        .map((each) => each.split(" ").reverse().join(" "));
      setQuaters(quats);
      setQuater(quats[0]);
    }
  }, [data]);

  useEffect(() => {
    if (!data) return;
    if (quater) {
      setTableData(data[quater][market]);
      setQuaterValue(quater);
    }
  }, [quater]);

  useEffect(() => {
    if (!quater) return;
    setTableData(data[quater][market]);
  }, [market]);

  return (
    <section className="w-full relative shadow-md xl:w-[35%] max-w-[20vw] opacity-90 hover:opacity-100 border rounded-lg pb-6">
      <div className="w-full flex justify-between px-5 py-3 items-center">
        <div className="w-[70%] 2xl:w-[70%] text-pot-dark-blue font-semibold text-sm">
          Top OTT Services by Viewers Reach
          <span className="inline-flex align-middle mb-1">
            <div
              onMouseEnter={() => setShowtooltip(true)}
              onMouseLeave={() => setShowtooltip(false)}
              className="-mb-1.5 min-w-[20px] relative"
            >
              <EditorPanelIcon primaryColor="rgb(23,103,165)" />
              {showTooltip && (
                <p className="px-2 py-0.5 text-xs w-[15vw] rounded-sm font-thin -left-10 z-[99] absolute bg-pot-dark-blue text-white">
                  Top Services in terms of viewer engagement across all the
                  business models.
                </p>
              )}
            </div>
          </span>
        </div>
        {quater && (
          <Dropdown
            small
            options={quaters}
            text={quaterValue}
            setValue={setQuater}
          />
        )}
      </div>
      {quater && (
        <div className="flex gap-3 px-5">
          <button
            onClick={() => setMarket("US")}
            className={`text-sm px-2 py-1 border rounded ${
              market === "US" && "bg-[#1B75BB] text-white border-[#1B75BB]"
            }`}
          >
            US
          </button>
          <button
            onClick={() => setMarket("CANADA")}
            className={`text-sm px-2 py-1 border rounded ${
              market === "CANADA" && "bg-[#1B75BB] text-white border-[#1B75BB]"
            }`}
          >
            Canada
          </button>
        </div>
      )}
      {!tableData ? (
        <div className="w-full h-60 flex justify-center items-center">
          <DotPulse size={20} speed={0.8} />
        </div>
      ) : (
        <div className="w-full mt-5">
          <TableLayoutTwo table={table} small />
        </div>
      )}
      <div className="w-full absolute bottom-0 py-2 flex justify-end text-xs px-2 text-pot-t-grey">
        © Parks Associates
      </div>
    </section>
  );
};

export default Table;
