import React, { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import {
  ResponsiveContainer,
  LineChart,
  XAxis,
  YAxis,
  Line,
  Legend,
  ReferenceLine,
  Tooltip,
  Label,
  CartesianGrid,
} from "recharts";
import {
  reachData1 as data1,
  reachData2 as data2,
} from "../../utils/chartsData";
import { AnimatePresence, motion } from "framer-motion";
import { DotPulse } from "@uiball/loaders";
import { useRecoilValue } from "recoil";
import { ResizingAtom, SelectedProfileCountryAtom } from "../../atom";

const VideoReachChart = ({ data }) => {
  const [market, setMarket] = useState("US");
  const containerResizing = useRecoilValue(ResizingAtom);
  const selectedProfileCountry = useRecoilValue(SelectedProfileCountryAtom);

  useEffect(() => {
    setMarket(selectedProfileCountry)
  }, [selectedProfileCountry])

  return (
    <section className="w-full border shadow-md rounded-lg">
      <p className="text-pot-dark-blue font-semibold text-sm px-5 py-5">
        State of the Market: OTT Video Viewers Reach
      </p>
      {!data || containerResizing ? (
        <div className="w-full h-96 flex justify-center items-center">
          <DotPulse size={40} color="#1767A5" />
        </div>
      ) : (
        <div className="w-full h-fit relative text-xs pb-5">
          <ResponsiveContainer width="98%" height={400}>
            <LineChart
              margin={{ right: 100, bottom: 30, left: 20 }}
              data={data[market]}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <Legend
                verticalAlign="top"
                layout="vertical"
                height={60}
                align={"center"}
                wrapperStyle={{ position: "absolute", right: "0%" }}
                formatter={(value) =>
                  value === "AVOD" ? (
                    <span className="text-pot-text-grey">
                      Total monthly viewers of all estimated ad-based OTT
                      services
                    </span>
                  ) : (
                    value === "SVOD" && (
                      <span className="text-pot-text-grey">
                        Total subscribers of all estimated subscription-based
                        OTT Services
                      </span>
                    )
                  )
                }
                iconType="circle"
                iconSize={10}
              />
              <XAxis
                label={{ value: "Time", position: "insideBottom", offset: -10 }}
                name="x_axis"
                dataKey="x_axis"
              />
              <YAxis
                label={{
                  value: "Total Viewers (M)",
                  angle: -90,
                  position: "insideLeft",
                }}
                tickCount={7}
              />
              <Tooltip />
              <ReferenceLine
                stroke="none"
                y={data[market].slice(-1)[0].SVOD}
                label={(props) => (
                  <CustomLabel
                    type="SVOD"
                    color="#028D9B"
                    value={data[market].slice(-1)[0].SVOD.toFixed(2)}
                    {...props}
                  />
                )}
                ifOverflow="extendDomain"
              >
                {/* <Label value={data[market].slice(-1)[0].SVOD.toFixed(2)} position="right" fill="red" /> */}
              </ReferenceLine>
              <ReferenceLine
                stroke="none"
                y={data[market].slice(-1)[0].AVOD}
                label={(props) => (
                  <CustomLabel
                    type="AVOD"
                    color="#1767A5"
                    value={data[market].slice(-1)[0].AVOD.toFixed(2)}
                    {...props}
                  />
                )}
                ifOverflow="extendDomain"
              >
                {/* <Label value={data[market].slice(-1)[0].AVOD.toFixed(2)} position="right" fill="blue" /> */}
              </ReferenceLine>
              <Line
                //   name="Total monthly viewers of all ad-based OTT services"
                type="monotone"
                dataKey={`SVOD`}
                stroke="#02A0B0"
              />
              <Line
                //   name="Total subscriptions to all subscription-based OTT services"
                //   nameKey="x"
                type="monotone"
                dataKey={`AVOD`}
                stroke="#1B75BB"
              />
              {/* <ReferenceLine */}
            </LineChart>
          </ResponsiveContainer>
          <div className="absolute -top-1 left-16 flex gap-5">
            <button
              onClick={() => setMarket("US")}
              className={`text-sm px-2 py-1 border rounded ${
                market === "US" && "bg-[#1B75BB] text-white border-[#1B75BB]"
              }`}
            >
              US
            </button>
            <button
              onClick={() => setMarket("CANADA")}
              className={`text-sm px-2 py-1 border rounded ${
                market === "CANADA" &&
                "bg-[#1B75BB] text-white border-[#1B75BB]"
              }`}
            >
              Canada
            </button>
          </div>
        </div>
      )}
      <div className="w-full pb-2 py-1 flex justify-end text-xs px-2 text-pot-t-grey">
        © Parks Associates
      </div>
    </section>
  );
};

const CustomLabel = ({ type, color, value, ...props }) => {
  const bgColor = type === "SVOD" ? "bg-[#028D9B]" : "bg-[#1767A5]";
  return (
    <foreignObject
      className="w-[100px] h-[16px]"
      x={props.viewBox.width + 85}
      y={props.viewBox.y - 8}
    >
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 2 }}
          className={`px-2 w-fit ${bgColor} text-white rounded`}
        >
          {value}
        </motion.div>
      </AnimatePresence>
    </foreignObject>
  );
};

export default VideoReachChart;
