import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { PdfColsAtom, PdfDataAtom, titleAtom } from "../../../atom";
import { titles } from "../../../utils/Titles";
import NPSbyService from "./NPSbyService";
import IndustryBenchmark from "./IndustryBenchmark";
import Banner from "../Banner";

const NPS = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  var specific_profile = queryParameters && queryParameters?.get("profile");
  const setTitle = useSetRecoilState(titleAtom);
  const [active, setActive] = useState(1);
  const setPdfCols = useSetRecoilState(PdfColsAtom);
  const setPdfData = useSetRecoilState(PdfDataAtom);

  const npsText =
    "NPS is a measure of how likely current subscribers are to recommend a service or product to others. The industry often uses this metric as a proxy for customer satisfaction and enthusiasm.\nTo calculate NPS, subscribers are asked to rate their willingness to recommend the service on a scale of 0 – 10 (with 10 being extremely likely). The percentage rating 0-6 is then deducted from the percentage rating 9 or 10.";

  const clearParams = () => {
    if (!specific_profile) return;
    queryParameters.delete("profile", specific_profile);
    const newUrl = window.location.origin + window.location.pathname;
    window.history.replaceState({}, "", newUrl);
  };

  useEffect(() => {
    setTitle("NPS");
    document.title = `Streaming Video Tracker | ${
      titles[window.location.pathname]
    }`;
  }, []);

  useEffect(() => {
    if (specific_profile) setActive(2);
  }, [specific_profile]);

  useEffect(() => {
    setPdfCols(null);
    setPdfData(null);
  }, [active]);

  return (
    <>
      <div className="z-50 sticky top-12">
        <div className="bg-white flex mb-5">
          <button
            onClick={() => {
              setActive(1);
              clearParams();
            }}
            className={`min-w-max border-b-[3px] ${
              active === 1 ? "border-[#8BB3D2]" : "text-pot-grey-3"
            } py-5 px-10 font-semibold`}
          >
            Industry Benchmark
          </button>
          <button
            onClick={() => {
              setActive(2);
            }}
            className={`min-w-max border-b-[3px] ${
              active === 2 ? "border-[#8BB3D2]" : "text-pot-grey-3"
            } py-5 px-10 font-semibold`}
          >
            NPS by service
          </button>
          <div className="w-full border-b-[3px]"></div>
        </div>
      </div>

      {/* Banner */}
      {active === 1 && <Banner heading="NPS" para={npsText} />}

      {active === 1 ? <IndustryBenchmark /> : <NPSbyService />}
    </>
  );
};

export default NPS;
