import React, { useEffect, useState } from "react";
import ColoredDownArrow from "../../assets/ColoredDownArrow";
import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  Bar,
  Legend,
  ReferenceLine,
  Tooltip,
  LabelList,
} from "recharts";
import Dropdown from "../../components/Dropdown";
import { ClipLoader } from "react-spinners";
import { motion } from "framer-motion";
import { DotPulse } from "@uiball/loaders";
import { useRecoilValue } from "recoil";
import { ResizingAtom, SelectedProfileCountryAtom } from "../../atom";

const TopsChart = ({
  text,
  data,
  label,
  mainData,
  devices = false,
  distribution = false,
  isPercentVal = false,
  isRoundVal = false,
  formatDecimal = false,
}) => {
  const [quaters, setQuaters] = useState(null);
  const [market, setMarket] = useState("US");
  const [quaterData, setQuaterData] = useState(null);
  const [quater, setQuater] = useState(null);
  const [quaterValue, setQuaterValue] = useState(null);
  const containerResizing = useRecoilValue(ResizingAtom);
  const selectedProfileCountry = useRecoilValue(SelectedProfileCountryAtom);

  useEffect(() => {
    setMarket(selectedProfileCountry);
  }, [selectedProfileCountry]);

  // console.log(data, quater, text, quaterData, quaterValue)
  console.log(quaterData?.[market], text, market, quaterData, data);

  useEffect(() => {
    if (data) {
      const quats = Object.keys(data)
        .map((each) => each.split(" ").reverse().join(" "))
        .sort()
        .reverse()
        .map((each) => each.split(" ").reverse().join(" "));
      setQuaters(quats);
      setQuater(quats[0]);
      setQuaterData(data[quater] || Object.values(data)[0]);
    }
  }, [data]);

  useEffect(() => {
    if (!data) return;
    if (quater) {
      setQuaterData(data[quater]);
      setQuaterValue(quater);
    }
  }, [quater]);

  useEffect(() => {
    if (!mainData) return;
    setQuaterData(mainData);
  }, [mainData]);

  return (
    <section className="w-full h-full border shadow rounded-lg opacity-90 hover:opacity-100 transition ease-in-out duration-500">
      <div className="w-full flex justify-between px-5 py-3 items-center">
        <p className="text-pot-dark-blue font-semibold text-sm">{text}</p>
        {quater && (
          <>
            <Dropdown
              options={quaters}
              text={quaterValue}
              setValue={setQuater}
            />
          </>
        )}
      </div>
      {!quaterData || containerResizing ? (
        <div className="w-full h-96 flex justify-center items-center">
          <DotPulse size={40} color="#1767A5" />
        </div>
      ) : (
        <div className="w-full h-fit relative text-xs pb-5">
          {quaterData[market]?.length ? (
            <ResponsiveContainer
              width={"97%"}
              // height={quater ? 400 : 350}
              height={400}
            >
              <BarChart
                margin={{
                  left: 130,
                  // top: quater ? 40 : 0,
                  top: 40,
                  bottom: 20,
                  right: 40,
                }}
                barGap={2}
                barCategoryGap={8}
                stackOffset="sign"
                data={quaterData[market]}
                layout="vertical"
              >
                {devices}
                <Bar shape={CustomizedBar} dataKey={"value"} fill="#1B75BB">
                  <LabelList
                    valueAccessor={(each) =>
                      `${
                        isRoundVal
                          ? Math.round(each.value)
                          : formatDecimal
                          ? each.value.toFixed(1)
                          : each.value.toFixed(2)
                      }${isPercentVal ? "%" : ""}`
                    }
                    position="right"
                  />
                </Bar>
                <XAxis
                  padding={{ left: 20, right: 20 }}
                  type="number"
                  label={{
                    value: label,
                    position: "insideBottom",
                    offset: -10,
                  }}
                />
                {/* <YAxis tickFormatter={(value) => value.split(" ").join("_")} tickMargin={8} interval={0} padding={{ top: 20, bottom: 20 }} type="category" dataKey="x_axis" /> */}
                <YAxis
                  tick={CustomizedTick}
                  label={{
                    value: distribution
                      ? "Distribution Platform"
                      : devices
                      ? "Device Types"
                      : "OTT Services",
                    angle: -90,
                    position: distribution ? "insideTopRight" : "insideLeft",
                    offset: distribution ? 140 : -90,
                  }}
                  interval={0}
                  padding={{ top: 20, bottom: 20 }}
                  type="category"
                  dataKey="x_axis"
                />
              </BarChart>
            </ResponsiveContainer>
          ) : (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="w-full h-96 flex justify-center items-center text-base font-semibold text-pot-text-grey-2"
            >
              No Chart Data
            </motion.div>
          )}
          {(quater || devices || distribution) && (
            <div className="absolute -top-1 left-8 flex gap-3">
              <button
                onClick={() => setMarket("US")}
                className={`text-sm px-2 py-1 border rounded ${
                  market === "US" && "bg-[#1B75BB] text-white border-[#1B75BB]"
                }`}
              >
                US
              </button>
              <button
                onClick={() => setMarket("CANADA")}
                className={`text-sm px-2 py-1 border rounded ${
                  market === "CANADA" &&
                  "bg-[#1B75BB] text-white border-[#1B75BB]"
                }`}
              >
                Canada
              </button>
            </div>
          )}
        </div>
      )}
      <div className="w-full pb-2 py-1 flex justify-end text-xs px-2 text-pot-t-grey">
        © Parks Associates
      </div>
    </section>
  );
};

const CustomizedTick = (props) => {
  return (
    <foreignObject
      className="w-[120px] h-[28px] flex justify-end items-center translate-y-[-6px]"
      x={60}
      y={props.y - 8}
    >
      <div className="w-full h-full flex justify-end items-center mt-[-0px]">
        <p className="w-fit text-right leading-3">{props.payload.value}</p>
      </div>
    </foreignObject>
  );
};

const CustomizedBar = (props) => {
  const color = `bg-[${props.fill}]`;

  return (
    <g>
      <foreignObject
        height={11}
        width={props.width}
        x={props.x}
        y={props.y + props.height / 2 - 11 / 2}
      >
        <div className={`${color} w-full h-full rounded-full`}></div>
      </foreignObject>
    </g>
  );
};

export default TopsChart;
