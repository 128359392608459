import React, { useState } from "react";
import ControlSelects from "../ViewerTrend/ControlSelects";
import Legends from "./Legends";
import TrendLineChart from "../ViewerTrend/Line";
import TrendBarChart from "../ViewerTrend/Bar";
import TrendHorizontalBarChart from "../ViewerTrend/HorizontalBar";
import TrendStackedAreaChart from "../ViewerTrend/StackedAreaChart";
import { useRecoilValue } from "recoil";
import {
  ChurnQuarterRangeAtom,
  NPSQuarterRangeAtom,
  QuarterRangeAtom,
} from "../../atom";
import { DotPulse } from "@uiball/loaders";

const SingleChart = ({
  title,
  chartType,
  setChartType,
  quarterEnd,
  quarterStart,
  setQuarterEnd,
  setQuarterStart,
  selectedOtts,
  setSelectedOtts,
  chartLoading,
  selectedBarQuarter,
  showFullScreen = false,
  showChartTypeDropdown = false,
  selectedChart,
  setSelectedChart,
  barData,
  page,
  description,
}) => {
  const quarterRange = useRecoilValue(
    page === "nps"
      ? NPSQuarterRangeAtom
      : page === "churn"
      ? ChurnQuarterRangeAtom
      : QuarterRangeAtom
  );
  const handleFullWidth = () => {
    if (selectedChart === 0) {
      if (title === "Chart 1") {
        setSelectedChart(1);
      } else if (title === "Chart 2") {
        setSelectedChart(2);
      }
    } else {
      setSelectedChart(0);
    }
  };

  return (
    <div className={`flex flex-col border rounded-md w-full divide-y`}>
      <div className="min-w-full w-full flex justify-between py-2 px-6">
        <p className="text-base flex items-center font-semibold">{title}</p>
        <ControlSelects
          chartType={chartType}
          setChartType={setChartType}
          quarterEnd={quarterEnd}
          quarterStart={quarterStart}
          setQuarterEnd={setQuarterEnd}
          setQuarterStart={setQuarterStart}
          showFullScreen={showFullScreen}
          showChartTypeDropdown={showChartTypeDropdown}
          handleFullWidth={handleFullWidth}
          selectedChart={selectedChart}
          customQuarterRange={quarterRange}
          page={page}
        />
      </div>
      <div
        style={{
          position: "relative",
          width: "100%",
          paddingBottom: "350px",
          paddingRight: "12px",
        }}
      >
        <div
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 0,
            top: 10,
          }}
        >
          {chartLoading && (
            <div className="w-full flex justify-center h-[350px] items-center">
              <DotPulse size={40} color="#1767A5" speed={0.8} />
            </div>
          )}
          {selectedOtts &&
            (chartType === "line" ? (
              <TrendLineChart
                selectedOtts={selectedOtts}
                quarterEnd={quarterEnd}
                quarterStart={quarterStart}
                chartLoading={chartLoading}
                page={page}
              />
            ) : chartType === "bar" ? (
              <TrendBarChart
                selectedOtts={selectedOtts}
                barData={barData}
                chartLoading={chartLoading}
                selectedBarQuarter={selectedBarQuarter}
                page={page}
              />
            ) : chartType === "horizontal" ? (
              <TrendHorizontalBarChart
                selectedOtts={selectedOtts}
                chartLoading={chartLoading}
                selectedBarQuarter={selectedBarQuarter}
              />
            ) : chartType === "area" ? (
              <TrendStackedAreaChart
                selectedOtts={selectedOtts}
                chartLoading={chartLoading}
                selectedBarQuarter={selectedBarQuarter}
              />
            ) : (
              <TrendLineChart
                selectedOtts={selectedOtts}
                quarterEnd={quarterEnd}
                quarterStart={quarterStart}
                chartLoading={chartLoading}
              />
            ))}
        </div>
        {/* <div className="pb-2 pt-1 pr-4 absolute bottom-0 right-0 text-xs pl-2 text-pot-t-grey">
          © Parks Associates
        </div> */}
      </div>
      <Legends selectedOtts={selectedOtts} setSelectedOtts={setSelectedOtts} />
      {description && (
        <div className="border-t-2 p-4 w-full text-right text-[rgb(139,139,139)]">
          {description}
        </div>
      )}
    </div>
  );
};

export default SingleChart;
