import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { DotPulse } from "@uiball/loaders";
import React from "react";
import { useRecoilValue } from "recoil";
import { ResizingAtom } from "../../atom";
import TableLayoutOne from "../../components/TableLayout/One";
import useTopFiltersCols from "../../hooks/useTopFiltersCols";

const TopFiltersTable = ({ fetch, setters = [] }) => {
  const {
    colDef,
    data = [],
    loading,
    setSelectedRegion,
    selectedRegion,
  } = useTopFiltersCols({ fetch, setters });
  const resizingLoading = useRecoilValue(ResizingAtom);

  const table = useReactTable({
    data: data,
    columns: colDef,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="w-full self-stretch border rounded-md shadow-md text-xs">
      <p className="text-sm text-pot-dark-blue font-semibold p-4">
        Top 10 Filters Applied For Every Search Dropdown
      </p>
      <div className="flex px-3 pb-2 gap-x-2">
        <button
          onClick={() => setSelectedRegion("US")}
          className={`text-sm px-2 py-1 border rounded ${
            selectedRegion === "US" && "bg-[#1B75BB] text-white border-[#1B75BB]"
          }`}
        >
          US
        </button>
        <button
          onClick={() => setSelectedRegion("CANADA")}
          className={`text-sm px-2 py-1 border rounded ${
            selectedRegion === "CANADA" && "bg-[#1B75BB] text-white border-[#1B75BB]"
          }`}
        >
          Canada
        </button>
      </div>
      <div className="w-full mb-1">
        <TableLayoutOne table={table} moreBorder small />
      </div>
      {!resizingLoading &&
        (loading ? (
          <div className="w-full  flex justify-center items-center">
            <DotPulse color="#1767A5" speed={0.8} size={28} />
          </div>
        ) : (
          !data.length && (
            <div className="w-full min-h-[40vh] flex justify-center items-center font-semibold text-lg text-pot-grey-4">
              No Data
            </div>
          )
        ))}
    </div>
  );
};

export default TopFiltersTable;
