import React, { useState, useEffect } from "react";
import { ClipLoader } from "react-spinners";
import { DotPulse } from '@uiball/loaders'
import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  Bar,
  Legend,
  ReferenceLine,
  Tooltip,
  LabelList,
} from "recharts";
import { data1, data2 } from "../../utils/chartsData";
import { useRecoilValue } from "recoil";
import { ResizingAtom, SelectedProfileCountryAtom } from "../../atom";
import EditorPanelIcon from "@atlaskit/icon/glyph/editor/panel";

const MarketStateChart = ({ stateOfMarket, showGraph = true }) => {
  const [market, setMarket] = useState("US");
  const [showTooltip, setShowtooltip] = useState(false)
  const containerResizing = useRecoilValue(ResizingAtom)
  const selectedProfileCountry = useRecoilValue(SelectedProfileCountryAtom);

  useEffect(() => {
    setMarket(selectedProfileCountry)
  }, [selectedProfileCountry])

  return (
    <section className="w-full shadow-md border opacity-90 hover:opacity-100 transition ease-in-out duration-500 rounded-lg">
      <div className="text-pot-dark-blue flex items-center font-semibold text-sm px-5 py-5">
        State of the Market: Total Number of Standalone OTT Services
        <div onMouseEnter={() => setShowtooltip(true)} onMouseLeave={() => setShowtooltip(false)} className="pt-1 px-2 relative">
          <EditorPanelIcon primaryColor="rgb(23,103,165)" />
          {showTooltip && (
            <p className="px-2 py-0.5 text-xs w-[30vw] rounded-sm font-thin -left-[24vh] z-[99] absolute bg-pot-dark-blue text-white">
              Total number of over-the-top services having a direct-to-customer app or web presence that Parks Associates track across North America
            </p>
          )}
        </div>
      </div>
      {!stateOfMarket || containerResizing ? (
        <div className="w-full h-96 flex justify-center items-center">
          <DotPulse size={40} color="#1767A5" />
        </div>
      ) : (
        <div className="w-full h-fit relative text-xs pb-5">
          {showGraph && <ResponsiveContainer width={"97%"} height={400}>
            <BarChart
              margin={{ left: 30, bottom: 10 }}
              barCategoryGap={5}
              stackOffset="sign"
              data={stateOfMarket[market]}
            >
              <XAxis
                label={{ value: "Year", position: "insideBottom", offset: -5 }}
                name="x_axis"
                axisLine={false}
                tickLine={false}
                dataKey="x_axis"
              />
              <Legend
                verticalAlign="top"
                height={60}
                align={"right"}
                formatter={(value) => (
                  <span className="text-pot-text-grey">
                    {value.slice(0, 1).toUpperCase() + value.slice(1)}
                  </span>
                )}
                iconType="circle"
                iconSize={10}
              />
              <Tooltip itemSorter={(item) => {
                switch(item.dataKey) {
                  case "launched": return 0
                  case "existing": return 1
                  case "closed": return 2
                }
              }} formatter={(value, name, props) => name==="closed" ? -value : value} cursor={{ fill: "#fff" }} />
              <YAxis
                label={{
                  value: "# of OTT Video Services",
                  angle: -90,
                  position: "insideLeft",
                }}
                tickCount={7}
              />
              <ReferenceLine y={0} stroke="#000000" />
              <ReferenceLine x={0} stroke="#000000" />
              <Bar dataKey="existing" stackId="a" fill="#1B75BB" />
              <Bar dataKey="launched" stackId="a" fill="#02A0B0">
                <LabelList valueAccessor={(each) => (each.existing + each.launched)} position={"top"} />
              </Bar>
              {/* <Bar dataKey="closed" stackId="a" fill="#0F436E"></Bar> */}
            </BarChart>
          </ResponsiveContainer>}
          <div className="absolute -top-1 left-16 flex gap-5">
            <button
              onClick={() => setMarket("US")}
              className={`text-sm px-2 py-1 border rounded ${
                market === "US" && "bg-[#1B75BB] text-white border-[#1B75BB]"
              }`}
            >
              US
            </button>
            <button
              onClick={() => setMarket("CANADA")}
              className={`text-sm px-2 py-1 border rounded ${
                market === "CANADA" &&
                "bg-[#1B75BB] text-white border-[#1B75BB]"
              }`}
            >
              Canada
            </button>
          </div>
        </div>
      )}
      <div className="w-full pb-2 flex justify-end text-xs px-2 text-pot-t-grey">
        © Parks Associates
      </div>
    </section>
  );
};

export default MarketStateChart;
