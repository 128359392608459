import React, { useEffect, useState } from "react";
import TrendBarChart from "./Bar";
import ControlSelects from "./ControlSelects";
import Legends from "./Legends";
import TrendLineChart from "./Line";
import TrendTable from "./Table";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  AllFilterOptionsAtom,
  QuarterRangeAtom,
  SelectedProfileCountryAtom,
  titleAtom,
} from "../../atom";
import { OttDataApi } from "../../api/ottData";
import SearchBox from "./SearchBox";
import { strokes } from "../../utils/chartsData";
import BarQuarterSelect from "./BarQuarterSelect";
import { titles } from "../../utils/Titles";
import { viewers_trend_chart_heading } from "../../utils/constants";
import { DotPulse } from "@uiball/loaders";

const ViewersTrend = () => {
  const [chartType, setChartType] = useState("line");
  const setTitle = useSetRecoilState(titleAtom);
  const allFilter = useRecoilValue(AllFilterOptionsAtom);
  const initialNamesList = allFilter.namesArr;
  const [namesList, setNamesList] = useState(initialNamesList);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const quarterRange = useRecoilValue(QuarterRangeAtom);
  const endQuarter = Number(quarterRange.max.split(" ")[0]) - 1;
  const endQuarterYear = Number(quarterRange.max.split(" ")[1]);
  const modableVal = endQuarter - 11;
  const startQuarter =
    modableVal % 4 < 0 ? 4 + (modableVal % 4) : modableVal % 4;
  const diff =
    modableVal % 4 !== 0
      ? Math.trunc(Math.abs(modableVal) / 4 + 1)
      : Math.trunc(Math.abs(modableVal) / 4);
  const startQuarterYear = endQuarterYear - diff;
  const [quarterStart, setQuarterStart] = useState([
    startQuarter,
    startQuarterYear,
  ]);
  const [quarterEnd, setQuarterEnd] = useState([endQuarter, endQuarterYear]);
  const [subsData, setSubsData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [initial, setInitial] = useState(true);
  const [selectedOtts, setSelectedOtts] = useState(null);
  const [chartLoading, setChartLoading] = useState(true);
  const [selectedBarQuarter, setSelectedBarQuarter] = useState(
    [endQuarter + 1, endQuarterYear].join(" ")
  );
  const [sortBy, setSortBy] = useState(`- ${selectedBarQuarter}`);
  const [clearOut, setClearOut] = useState(false);
  const [selectedOttChangedInFetch, setSelectedOttChangedInFetch] =
    useState(false);
  const [type, setType] = useState("PAID_SUBS");
  const [lastRecord, setLastRecord] = useState(false);
  const selectedProfileCountry = useRecoilValue(SelectedProfileCountryAtom);

  const fetchSubsData = async (
    reset = false,
    calledInitial = false,
    callForSelected = false
  ) => {
    if (lastRecord && !reset) return;
    const startQ = [quarterStart[0] + 1, quarterStart[1]];
    const endQ = [quarterEnd[0] + 1, quarterEnd[1]];
    let params = { length: 20, page: reset ? 1 : page + 1 };
    params.profile_country = selectedProfileCountry;
    const pagex = callForSelected ? 0 : page;
    const namesEnd =
      namesList.length < pagex * 20 + 20 ? namesList.length : pagex * 20 + 20;
    const names = namesList
      .slice(pagex * 20, namesEnd)
      .map((each) => each.value);
    // callForSelected ? namesList.slice(page * 20, namesEnd).map((each) => each.value).concat(selectedOtts.map(each => each.id)) :
    let payload = {
      filters: {
        names,
      },
      q_range: {
        start: startQ.join(" "),
        end: endQ.join(" "),
      },
      kind: type,
      // sort_by: `+ 3 2021`
    };

    if (sortBy) {
      // if(searchValue) setClearOut(true)
      // payload.sort_by = sortBy
      if (!searchValue) {
        delete payload.filters;
        setNamesList(initialNamesList);
        payload.sort_by = sortBy;
      }
    }
    setIsLoading(true);
    const { data: response } = await OttDataApi.subData(payload, { params });
    setIsLoading(false);
    if (response.data?.total_pages === params.page) {
      setLastRecord(true);
    }
    if (response.errors?.error_message[0].includes("Invalid page")) {
      setLastRecord(true);
      return;
    }
    // const filteredData = [...response.data.results]
    // if(callForSelected) {
    //   const x = filteredData.slice(-(selectedOtts.length))
    //   const settableX = selectedOtts.map(each => )
    //   setSelectedOtts()
    //   filteredData.splice(-(selectedOtts.length))
    // }
    let formattedSubsData = response.data.results.map((each) => ({
      ...each,
      ...each.subs,
    }));

    let settableSelOtts = selectedOtts ? [...selectedOtts] : null;

    if (callForSelected) {
      params = { length: selectedOtts.length };
      payload = {
        ...payload,
        filters: { names: selectedOtts.map((each) => each.id) },
      };
      delete payload.sort_by;
      const { data: response2 } = await OttDataApi.subData(payload, { params });
      settableSelOtts = response2.data.results.map((each) => {
        const selOtt = selectedOtts.find((x) => x.id === each.id);
        return { ...each, ...each.subs, color: selOtt.color };
      });
      setSelectedOttChangedInFetch(true);
      setSelectedOtts(settableSelOtts);
      setChartLoading(false);
    }
    if (initial) {
      setInitial(false);
    }
    if (calledInitial) {
      const values = formattedSubsData
        .slice(0, 10)
        .map((each, id) => ({ ...each, color: strokes[id] }));
      setSelectedOtts(values);
    }
    if (!sortBy && !searchValue && !calledInitial) {
      formattedSubsData = formattedSubsData.filter(
        (each) => !settableSelOtts.find((ott) => ott.id === each.id)
      );
      if (page === 0 || reset) {
        formattedSubsData = [...settableSelOtts, ...formattedSubsData];
      }
    }
    setSubsData((prev) =>
      reset ? formattedSubsData : [...prev, ...formattedSubsData]
    );
    if (chartLoading) {
      setChartLoading(false);
    }
  };

  useEffect(() => {
    setTitle("Viewers Trend");
    document.title = `Streaming Video Tracker | ${
      titles[window.location.pathname]
    }`;
    fetchSubsData(false, true);
  }, []);

  useEffect(() => {
    if (initial) return;
    fetchSubsData();
  }, [page]);

  useEffect(() => {
    if (initial) return;
    setChartLoading(true);
    setNamesList(allFilter.namesArr);
  }, [allFilter]);

  useEffect(() => {
    if (initial) return;
    if (clearOut) {
      setClearOut(false);
      return;
    }
    setLastRecord(false);
    setPage(0);
    if (chartLoading) {
      fetchSubsData(true, true, true);
    } else {
      fetchSubsData(true);
    }
  }, [namesList, sortBy]);

  const typeBasedCall = async () => {
    setPage(0);
    setLastRecord(false);
    setChartLoading(true);
    setClearOut(true);
    await fetchSubsData(true, true, true);
    setClearOut(false);
    setChartLoading(false);
  };

  useEffect(() => {
    if (initial) return;
    typeBasedCall();
  }, [type]);

  useEffect(() => {
    if (initial) return;
    setPage(0);
    setLastRecord(false);
    if (!searchValue) {
      setNamesList([...initialNamesList]);
      return;
    }
    const filteredOttsStartWith = initialNamesList.filter((each) =>
      each.label.toLowerCase().startsWith(searchValue.toLowerCase())
    );
    const filteredOttsIncludes = initialNamesList.filter(
      (each) =>
        (each.label.toLowerCase().includes(searchValue.toLowerCase()) &&
          !each.label.toLowerCase().startsWith(searchValue.toLowerCase())) ||
        (each.label.toLowerCase() == "prime video" &&
          searchValue.toLowerCase().startsWith("ama")) //Note: This is hardcoded as per client's request. They wanted amazon to show prime video in suggestion
    );
    setNamesList([...filteredOttsStartWith, ...filteredOttsIncludes]);
  }, [searchValue]);

  useEffect(() => {
    if (initial) return;
    setChartLoading(true);
    setSelectedBarQuarter([endQuarter + 1, endQuarterYear].join(" "));
    setPage(0);
    setLastRecord(false);
    fetchSubsData(true, false, true);
  }, [quarterEnd, quarterStart]);

  useEffect(() => {
    if (initial) return;
    if (searchValue) return;
    if (selectedOttChangedInFetch) {
      setSelectedOttChangedInFetch(false);
      return;
    }
    let formattedSubsData = subsData.filter(
      (each) => !selectedOtts.find((ott) => ott.id === each.id)
    );
    formattedSubsData = [...selectedOtts, ...formattedSubsData];
    setSubsData(formattedSubsData);
  }, [selectedOtts]);

  return (
    <div className="border rounded-md py-4 pl-6 pr-3 m-5 text-xs">
      <div className="w-full flex justify-between mb-3 pr-3">
        <p className="text-sm text-pot-blue font-semibold">
          {viewers_trend_chart_heading[type]}
        </p>
        {chartType === "bar" && (
          <BarQuarterSelect
            selectedBarQuarter={selectedBarQuarter}
            setSelectedBarQuarter={setSelectedBarQuarter}
            quarterEnd={quarterEnd}
            quarterStart={quarterStart}
          />
        )}
        <ControlSelects
          chartType={chartType}
          setChartType={setChartType}
          quarterEnd={quarterEnd}
          quarterStart={quarterStart}
          setQuarterEnd={setQuarterEnd}
          setQuarterStart={setQuarterStart}
        />
      </div>
      <div
        style={{
          position: "relative",
          width: "100%",
          paddingBottom: "350px",
          paddingRight: "12px",
        }}
      >
        <div
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 0,
            top: 0,
          }}
        >
          {chartLoading && (
            <div className="w-full flex justify-center h-[350px] items-center">
              <DotPulse size={40} color="#1767A5" speed={0.8} />
            </div>
          )}
          {selectedOtts &&
            (chartType === "line" ? (
              <TrendLineChart
                selectedOtts={selectedOtts}
                quarterEnd={quarterEnd}
                quarterStart={quarterStart}
                chartLoading={chartLoading}
                type={type}
              />
            ) : (
              <TrendBarChart
                selectedOtts={selectedOtts}
                chartLoading={chartLoading}
                selectedBarQuarter={selectedBarQuarter}
                type={type}
              />
            ))}
        </div>
        <div className="pb-2 pt-1 pr-4 absolute bottom-0 right-0 text-xs pl-2 text-pot-t-grey">
          © Parks Associates
        </div>
      </div>
      <Legends selectedOtts={selectedOtts} setSelectedOtts={setSelectedOtts} />
      <SearchBox
        setSearchValue={setSearchValue}
        clearOut={clearOut}
        active={type}
        setActive={setType}
      />
      <TrendTable
        subsData={subsData}
        quarterStart={quarterStart}
        quarterEnd={quarterEnd}
        page={page}
        setPage={setPage}
        totalNames={namesList.length}
        selectedOtts={selectedOtts}
        setSelectedOtts={setSelectedOtts}
        setSortBy={setSortBy}
        sortBy={sortBy}
        lastRecord={lastRecord}
        isLoading={isLoading}
      />
    </div>
  );
};

export default ViewersTrend;
