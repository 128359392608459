import React, { useEffect, useState } from "react";
import Modal, {
  ModalBody,
  ModalHeader,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import CrossIcon from "@atlaskit/icon/glyph/cross";
import CommonChart from "../../../components/CommonChart";
import usePreviewNPSCharts from "../../../hooks/usePreviewNPSCharts";
import { DotPulse } from "@uiball/loaders";

const Preview = ({
  openPreview,
  setOpenPreview,
  previewData,
  setPreviewData,
}) => {
  // STATES
  const params = previewData && { id: previewData?.id };
  const [initial, setInitial] = useState(true);
  const { charts, loading, callAgain } = usePreviewNPSCharts(initial, params);

  // USE EFFECTS
  useEffect(() => {
    if (charts.length > 0) {
      setInitial(false);
    }
  }, [charts]);

  useEffect(() => {
    if (previewData) {
      setInitial(true);
      callAgain();
    }
  }, [previewData]);

  const handleClose = () => {
    setPreviewData("");
    setOpenPreview(false);
  };

  return (
    <ModalTransition>
      {openPreview && (
        <div className="rounded-xl bg-pot-grey-3">
          <Modal onClose={handleClose} width={"70vw"}>
            <ModalHeader>
              <h1 className="flex text-md w-full font-bold text-pot-grey-5">
                Preview
              </h1>
              <div className="cursor-pointer" onClick={handleClose}>
                <CrossIcon size="10px" />
              </div>
            </ModalHeader>
            <ModalBody>
              <div className="flex flex-col gap-4 pb-4">
                {loading && (
                  <div className="w-full h-[40vh] flex justify-center items-center pb-[14vh]">
                    <DotPulse size={40} color="#1767A5" />
                  </div>
                )}
                {!loading && charts?.data?.length > 0 && (
                  <CommonChart
                    id={previewData?.id}
                    title={previewData?.name}
                    footer={previewData?.footer_text}
                    data={charts?.data}
                    bar={charts?.bar}
                    page={previewData?.page}
                    yAxisLabel={previewData?.y_axis_label}
                    quarterRange={previewData?.quarter_range}
                  />
                )}
              </div>
            </ModalBody>
          </Modal>
        </div>
      )}
    </ModalTransition>
  );
};

export default Preview;
